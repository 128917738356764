import React from "react"
import { useNavigate } from "react-router-dom"

const SocialMedia = (props) => {
  const { images, moreLinks, buttonText, socialMediaTitle, socialMediaPath } =
    props?.data
  const navigate = useNavigate()
  return (
    <div className="col-span-2  h-full mt-4 md:mt-0">
      <div className="bg-white" style={{ height: "inherit" }}>
        <p className="font-semibold font-degularDisplay text-2xl leading-6 text-black md:pt-8 pt-4 mx-8">
          {socialMediaTitle}
        </p>
        <div className="ml-[25px] mr-[27px] mt-4">
          <div className="grid grid-cols-4 gap-2">
            {images?.slice(0, 8)?.map((info, index) => (
              <img
                key={index}
                src={info?.title}
                alt="instagram_post"
                className="cursor-pointer"
                onClick={() => navigate(info?.path)}
              />
            ))}
          </div>
        </div>
        {buttonText && (
          <a href={socialMediaPath} target="_blank" rel="noopener noreferrer">
            <button className="font-medium leading-5 tracking-widest font-sfPro text-base px-[21px] py-[13px] bg-[#FFBD00] rounded-[70px] mx-auto mt-5 flex">
              {buttonText}
            </button>
          </a>
        )}
        <div className="border border-[#E9E9E9] mx-2.5 mt-5"></div>
        <p className="font-semibold text-2xl leading-6 font-degularDisplay mt-[30px] mx-8 mb-2.5">
          Recent Posts
        </p>
        {moreLinks?.map((info, index) => (
          <div key={index}>
            <p
              className="text-sm leading-[17px] font-sfPro font-normal text-[#BE000A] mx-8 mt-[14px] cursor-pointer"
              onClick={() => navigate(`#${info?.path}`)}
            >
              {info?.title}
            </p>
            <div className="border border-[#E9E9E9] mx-2.5 mt-[14px]"></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SocialMedia

import React from "react"
import BlogCard from "./BlogCard"
import { useNavigate } from "react-router-dom"

const BlogNavHeader = (props) => {
  const { navitem, blogitems, title, subtitle } = props
  const navigate = useNavigate()
  return (
    <div className="md:px-0 px-2.5">
      <div className="grid grid-cols-3 mt-9  mb-8 md:pb-20 pb-6">
        <div className="md:col-span-1 col-span-full md:pr-[150px] pr-0">
          <h2 className="text-[46px] leading-6 font-bold text-black  font-degularDisplay">
            {title}
          </h2>
          <p className="text-lg text-[#6C6C6C] md:mt-[46px] mt-5 font-sfPro">
            {subtitle}
          </p>
          <p className="text-lg text-[#6C6C6C] mt-7">JUMP TO</p>
          <div className="border-b border-[#DCCE9B] mt-2.5"></div>
          {navitem?.map((item, index) => (
            <div key={index}>
              <div
                className="mt-4 flex justify-between items-center"
                onClick={() => navigate(item?.path)}
              >
                <p className="text-base leading-6 font-semibold font-sfPro text-black ">
                  {item?.title}
                </p>
                <img src={"/rightArrow.svg"} alt="arrow" />
              </div>
              <div className="border-b border-[#DCCE9B] mt-4"></div>
            </div>
          ))}
        </div>
        <div className="md:col-span-2 col-span-full  flex md:flex-row flex-col  md:gap-6 gap-3 mt-3 md:mt-0 md:pt-0 pt-4 w-full">
          {blogitems?.slice(0, 2)?.map((data, index) => (
            <BlogCard {...data} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default BlogNavHeader

import React from "react"
import CloseImg from "../../images/Close.png"

const IframePopupComponentCaseStudy = ({ openIframePopup, onClose, queryParamsWithOutHashCaseStudy }) => {
  const handleClose = () => {
    onClose()
  };

  return (
    <div>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 transition-opacity duration-300 ${
          openIframePopup ? "opacity-100" : "opacity-0 hidden"
        }`}
      >
        <div className="bg-white rounded-[20px] max-w-xl min-w-[90%] sm:min-w-[390px] lg:min-w-[500px] max-h-[100%] relative z-50 custom-scrollbar">
          <button
            className="absolute top-2.5 right-2.5 bg-transparent text-black border-none p-2.5 cursor-pointer z-50"
            onClick={handleClose}
          >
            <img src={CloseImg} alt="CloseImg" />
          </button>

          <iframe
            src={queryParamsWithOutHashCaseStudy}
            className="w-full h-[477px] lg:h-[442px] border-none rounded z-40 overflow-auto custom-scrollbar"
            id="inline-8icV18hKzwL3ygpgTVVg"
            data-layout="{'id':'INLINE'}"
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value=""
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name="Case Study Email Request"
            data-height="451"
            data-layout-iframe-id="inline-8icV18hKzwL3ygpgTVVg"
            data-form-id="8icV18hKzwL3ygpgTVVg"
            title="Case Study Email Request"
          ></iframe>
        </div>
      </div>

      <style jsx>{`
        .spinner-loader-case {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 6px solid rgba(0, 0, 0, 0.1);
          border-top: 6px solid #000;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% {
            transform: translate(-50%, -50%) rotate(0deg);
          }
          100% {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
      `}</style>
    </div>
  )
}

export default IframePopupComponentCaseStudy

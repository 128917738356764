import GaEvent from "."

export const eventsHandler = (params, eventName) => {
  params = {
    customer_id: params?.customer_id || "na",
    website: params?.website || "na",
    phone: params?.phone || "na",
    restaurent_name: params?.restaurant_name || "na",
    source_page: params?.source || "na",
    customer_name: params?.full_name || "na",
    customer_email: params?.email || "na",
  }
  GaEvent({
    action: eventName,
    params: params,
  })
}

import React from "react"
import { useNavigate } from "react-router-dom"

const BlogCard = (props) => {
  const { imgUrl, readtime, date, description, title, item_type, imgPath } =
    props

  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/blog-details/${imgPath}`)
  }

  return (
    <div className="rounded w-full bg-white cursor-pointer">
      <div className="relative" onClick={handleClick}>
        <div className="absolute px-[14px] py-1 bg-white top-[11px] left-[11px] rounded-sm">
          <p className="text-sm leading-[14px] font-medium font-sfPro text-black">
            {item_type}
          </p>
        </div>
        <img src={imgUrl} alt="blog_image" className="rounded w-full" />
      </div>
      <div className="bg-white px-4 py-[14px] w-full rounded-b">
        <p className="font-sfPro font-semibold text-lg text-black">{title}</p>
        <p className="font-normal text-base text-[#667085] mt-[5px]">
          {description}
        </p>
        <div className="flex text-[#BE000A] justify-between mt-2.5">
          <p className="font-semibold text-sm">{date}</p>
          <p className="font-normal text-sm">{readtime}</p>
        </div>
      </div>
    </div>
  )
}

export default BlogCard

const BASE_URL = "https://orderbyte.io/api/v1/";
const BASE_URL_BLOG = "https://orderbyte.io/api/blog/v1/";
const BASE_URL_NEWS = "https://orderbyte.io/api/news/v1/";

async function requestData(url, method, params = {}) {
  let apiUrl = BASE_URL + url;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify(params);

  var requestOptions = {};
  if (method === "POST") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else if (method === "PUT") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else {
    requestOptions = {
      method: method,
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };
  }

  // console.log("requestOptions",requestOptions);

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result);
      return result;
    })
    .catch((error) => console.log("error", error));
}

async function requestDataBlog(url, method, params = {}) {
  let apiUrl = BASE_URL_BLOG + url;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("auth", "b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl");
  var raw = JSON.stringify(params);

  var requestOptions = {};
  if (method === "POST") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else if (method === "PUT") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else {
    requestOptions = {
      method: method,
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };
  }

  // console.log("requestOptions",requestOptions);

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result);
      return result;
    })
    .catch((error) => console.log("error", error));
}

async function requestDataNews(url, method, params = {}) {
  let apiUrl = BASE_URL_NEWS + url;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("auth", "b3JkZXJfYmxvZ19zZXJ2aWNlX2FwaV9ieXRl");
  var raw = JSON.stringify(params);

  var requestOptions = {};
  if (method === "POST") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else if (method === "PUT") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else {
    requestOptions = {
      method: method,
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };
  }

  // console.log("requestOptions",requestOptions);

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result);
      return result;
    })
    .catch((error) => console.log("error", error));
}

async function requestDataOnboarding(url, method, params = {}) {
  let apiUrl = BASE_URL + url;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Basic eUJjcGFaY2tWLWNxb2xocTBXcENKNGZ0Q0ZmWkUzYl9CX09vWUVpWFdpQTo=");
  var raw = JSON.stringify(params);

  var requestOptions = {};
  if (method === "POST") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else if (method === "PUT") {
    requestOptions = {
      method: method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  } else {
    requestOptions = {
      method: method,
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };
  }

  // console.log("requestOptions",requestOptions);

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // console.log(result);
      return result;
    })
    .catch((error) => console.log("error", error));
}

async function recaptcha(url, method, file) {
 
  let headers = {
    "Content-Type": "application/json",
  }; 
  var requestOptions = {
    method: method,
    body: file,
    mode: "cors",
    crossDomain: true,
    headers: headers,
  };

  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => {return result;})
    .catch((error) => {return error;});
}

async function googleSheet(url, method, file) {
 
  var requestOptions = {
    method: method,
    body: file,
  };

  return await fetch(url, requestOptions)
    .then((response) => response)
    .then((result) => {return result;})
    .catch((error) => {return error;});
}

async function googleReCaptcha (url,method,file) {
  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

var requestOptions = {
  method: method,
  headers: myHeaders,
  body: file,
  redirect: 'follow'
};

return await fetch(url, requestOptions)
  .then(response => response.json())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
}

async function fileUplode(
  url,
  method,
  file,
  object_get = {},
  tokenCustom = null
) {
  let apiUrl = BASE_URL + url;
  let headers = {
    "Content-Type": "multipart/form-data",
  };

  return await fetch(apiUrl, {
    method: method,
    body: file,
    mode: "cors",
    crossDomain: true,
    headers: headers,
  })
    .then((response) => {
      return response.json();
    })
    .then(
      (result) => {
        // console.log(result);
        return result;
      },
      (error) => {
        return error;
      }
    );
}

async function upload(url, method, file) {

  let apiUrl = BASE_URL + url;
  var requestOptions = {
    method: method,
    body: file,
    redirect: "follow",
  };

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {return result;})
    .catch((error) => {return error;});
}

export default {
  requestData,
  requestDataOnboarding,
  requestDataBlog,
  requestDataNews,
  fileUplode,
  upload,
  recaptcha,
  googleReCaptcha,
  googleSheet
};

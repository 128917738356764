import React from "react";

const Loader = () => {
  return (
    <div className="w-full h-full fixed bg-[#EAE4CE] z-[9999] flex items-center justify-center">
      <div className="border-[5px] border-white/30 border-t-white rounded-full w-[40px] h-[40px] animate-spin"></div>
    </div>
  );
};

export default Loader;

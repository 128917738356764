export const resTypeOptions = [
  { value: "American burgers", label: "🍔 American burgers" },
  { value: "American BBQ", label: "🍖 American BBQ" },
  { value: "Breakfast", label: "🍳 Breakfast" },
  { value: "Chicken", label: "🍗 Chicken" },
  { value: "Chinese", label: "🍜 Chinese" },
  { value: "Falafel", label: "🧆 Falafel" },
  { value: "Hot dogs", label: "🌭 Hot dogs" },
  { value: "Indian", label: "🍛 Indian" },
  { value: "Italian pizzas", label: "🍕 Italian pizzas" },
  { value: "Italian pastas", label: "🍝 Italian pastas" },
  { value: "Japanese", label: "🍣 Japanese " },
  { value: "Kebab", label: "🌯 Kebab" },
  { value: "Mediterranean", label: "🥙 Mediterranean" },
  { value: "Mexican tacos", label: "🌮 Mexican tacos" },
  { value: "Mexican burritos", label: "🌯 Mexican burritos"},
  { value: "Pan-Asian", label: "🥘 Pan-Asian"},
  { value: "Poke", label: "🍲 Poke"},
  { value: "Pastry", label: "🍰 Pastry"},
  { value: "Sandwiches/subs - deli", label: "🥪 Sandwiches/subs - deli"},
  { value: "Salads/Vegetarian", label: "🥗 Salads/Vegetarian"},
  { value: "Seafood", label: "🦞 Seafood"},
  { value: "Thai", label: "🍢 Thai"},
];

export const hearAboutOptions = [
  { value: "Email", label: "Email" },
  { value: "Google/Search Engine", label: "Google/Search Engine" },
  { value: "Facebook", label: "Facebook" },
  { value: "Instagram", label: "Instagram" },
  { value: "LinkedIn", label: "LinkedIn" },
  { value: "TikTok", label: "TikTok" },
  { value: "Trade Show/Event", label: "Trade Show/Event" },
  { value: "Referral", label: "Referral" },
  { value: "Other", label: "Other" },
];
import React from "react";
import Header from "../HeaderComponent";
import useUserForm from "../../hooks/user/useInternal";
import useLocalStorage from "../../hooks/user/useLocalStorageInternal";
import submitData from "../../hooks/user/internalSubmit";
import { resTypeOptions, hearAboutOptions } from "./selectOption";
import LocationSearchInput from "./LocationSearchInput";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InternalForm = ({ siteName }) => {
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData, errors, validateForm] = useUserForm();
  const { saveToLocalStorage, getFromLocalStorage } =
    useLocalStorage("formData");

  let decodedValue = "";

  // Get the current URL
  const currentUrl = window.location.href;
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("root")) {
    const encodedValue = currentUrl.split("=")[1];

    // Decode the base64-encoded value
    decodedValue = atob(decodeURIComponent(encodedValue));

    // console.log("decodedValue-----------", decodedValue);
  } else {
    // console.log("No 'root' parameter in the URL.");
  }
  React.useEffect(() => {
    localStorage.setItem("siteName", siteName);
  }, []);

  React.useEffect(() => {
    // Load form data from local storage when the component mounts
    const lastFilledComponent = getFromLocalStorage();
    // console.log("lastFilledComponent", lastFilledComponent);
    if (lastFilledComponent) {
      setFormData({
        restaurantName: lastFilledComponent.restaurantName || "",
        restaurantType: lastFilledComponent.restaurantType || "",
        restaurantAddress: lastFilledComponent.restaurantAddress || "",
        restaurantWebsite: lastFilledComponent.restaurantWebsite || "",
        color: lastFilledComponent.color || "",
        restaurantMenu: lastFilledComponent.restaurantMenu || "",
        fullName: lastFilledComponent.fullName || "",
        email: lastFilledComponent.email || "",
        phone: lastFilledComponent.phone || "",
        hearAbout: lastFilledComponent.hearAbout || ""
      });
    }
  }, [setFormData]);

  const handleInputChangeWithStorageUpdate = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      // console.log("formData---", updatedData); // Log the updated data
      saveToLocalStorage(updatedData); // Update local storage with the latest form data
      return updatedData; // Return the updated state
    });
  };

  const setLocation = (locationData) => {
    // Handle the location data in the parent component as needed
    console.log("Location data:", locationData);
    // Example: Update the state with location data
    setFormData((prevData) => ({
      ...prevData,
      latitude: locationData.latitude,
      longitude: locationData.longitude,
    }));
  };

  const handleSubmissionComplete = (loaderStatus) => {
    // Handle loader logic based on loaderStatus
    setLoading(loaderStatus);
    // Additional logic if needed
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();
    if (await validateForm(siteName)) {
      setLoading(true);
      await submitData(
        formData,
        siteName,
        decodedValue,
        handleSubmissionComplete
      );
      localStorage.clear();
      // setShowForm("thanks");
      if (loading === false) {
        toast.success("Data sent successfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      setFormData({
        restaurantMenu: "",
        restaurantName: "",
        restaurantType: "",
        restaurantWebsite: "",
        restaurantAddress: "",
        color: "",
        restaurantLogo: "",
        fullName: "",
        email: "",
        phone: "",
        hearAbout: ""
      });
    } else {
      console.error(
        "Form submission failed. Please check the form for errors."
      );
      toast.error("Form submission failed. Please check the form for errors.", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <div className="bg-[#F8F4E6] h-full pb-6">
      <ToastContainer />
        <div className="px-0 md:px-8 xl:px-0">
          <div id="TOP"></div>
          <Header />
          <div className="pt-44 sm:pt-48">
            <div className="w-full md:container px-5 xl:px-0">
              <form className="w-full max-w-full px-[calc(50vw/13.66)] styled-form">
              <div className="form-group mb-5">
                  <label
                    htmlFor="name"
                    className="block font-medium text-[calc(16vw/19.2)] text-[#22134b]"
                  >
                    1. Your menu [URL] link<span className="text-[#061737]">*</span>
                  </label>
                  <input
                    type="text"
                    className="w-full border-b border-[#22134b] bg-transparent py-2 text-[calc(16vw/19.2)] outline-none"
                    id="restaurantMenu"
                    name="restaurantMenu"
                    placeholder="e.g. www.stellaspizza.com/menu"
                    value={formData.restaurantMenu}
                    onChange={handleInputChangeWithStorageUpdate}
                    required
                  />
                  <span className="text-[#ec00b1]">
                    {errors.restaurantMenu}
                  </span>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="name"
                    className="block font-medium text-[calc(16vw/19.2)] text-[#22134b]"
                  >
                    2. Enter restaurant name<span className="text-[#061737]">*</span>
                  </label>
                  <input
                    type="text"
                    className="w-full border-b border-[#22134b] bg-transparent py-2 text-[calc(16vw/19.2)] outline-none"
                    id="restaurantName"
                    name="restaurantName"
                    placeholder="e.g. Stella’s Pizza"
                    value={formData.restaurantName}
                    onChange={handleInputChangeWithStorageUpdate}
                    required
                  />
                  <span className="text-[#ec00b1]">
                    {errors.restaurantName}
                  </span>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="deliveryType"
                    className="block font-medium text-[calc(16vw/19.2)] text-[#22134b]"
                  >
                    3. Select which best describes your food<span className="text-[#061737]">*</span>
                  </label>
                  <select
                    className="w-full border-b border-[#22134b] bg-transparent py-2 text-[calc(16vw/19.2)] outline-none"
                    id="restaurantType"
                    name="restaurantType"
                    onChange={handleInputChangeWithStorageUpdate}
                    value={formData.restaurantType}
                  >
                    <option value="" className="text-[#C8BFE5]">
                      Please select
                    </option>
                    {resTypeOptions.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <span className="text-[#ec00b1]">
                    {errors.restaurantType}
                  </span>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="name"
                    className="block font-medium text-[calc(16vw/19.2)] text-[#22134b]"
                  >
                    4. Enter restaurant website [if any]<span className="text-[#061737]">*</span>
                  </label>
                  <input
                    type="text"
                    className="w-full border-b border-[#22134b] bg-transparent py-2 text-[calc(16vw/19.2)] outline-none"
                    id="restaurantWebsite"
                    name="restaurantWebsite"
                    placeholder="e.g. Stella’s Pizza"
                    value={formData.restaurantWebsite}
                    onChange={handleInputChangeWithStorageUpdate}
                    required
                  />
                  <span className="text-[#ec00b1]">
                    {errors.restaurantWebsite}
                  </span>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="restaurant-address"
                    className="block font-medium text-[calc(16vw/19.2)] text-[#22134b]"
                  >
                    5. Restaurant address
                    <span className="text-[#061737]">*</span>
                  </label>
                  <LocationSearchInput
                    className="w-full border-b border-[#22134b] bg-transparent py-2 text-[calc(16vw/19.2)] outline-none"
                    name="restaurantAddress"
                    callback={(address) =>
                      handleInputChangeWithStorageUpdate({
                        target: { name: "restaurantAddress", value: address },
                      })
                    }
                    setLocation={setLocation}
                    placeholder="Enter restaurant address"
                    restaurantAddress={formData.restaurantAddress}
                  />
                  <span className="text-[#ec00b1]">
                    {errors.restaurantAddress}
                  </span>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="name"
                    className="block font-medium text-[calc(16vw/19.2)] text-[#22134b]"
                  >
                    6. Color<span className="text-[#061737]">*</span>
                  </label>
                  <div className="color-box pt-4 pl-14">
                    {[
                      "#F9E000",
                      "#FF9900",
                      "#AD002A",
                      "#80FF00",
                      "#000000",
                    ].map((color) => (
                      <label key={color} className="relative inline-block">
                        <input
                          type="checkbox"
                          className="hidden"
                          value={color}
                          name="color"
                          checked={formData.color === color}
                          onChange={handleInputChangeWithStorageUpdate}
                        />
                        <span
                          style={{ background: color }}
                          className={`w-10 h-10 inline-block rounded-full border-2 mr-3 ${
                            formData.color === color
                              ? "border-[#000]"
                              : "border-transparent"
                          }`}
                        />
                      </label>
                    ))}
                  </div>
                  <span className="text-[#ec00b1]">{errors.color}</span>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="name"
                    className="block font-medium text-[calc(16vw/19.2)] text-[#22134b]"
                  >
                    7. Upload restaurant logo [optional]<span className="text-[#061737]">*</span>
                  </label>
                  <input
                    type="file"
                    className="w-full border-b border-[#22134b] bg-transparent py-2 text-[calc(16vw/19.2)] outline-none"
                    id="restaurantLogo"
                    name="restaurantLogo"
                    placeholder="e.g. Stella’s Pizza"
                    value={formData.restaurantLogo}
                    onChange={handleInputChangeWithStorageUpdate}
                    required
                  />
                  <span className="text-[#ec00b1]">
                    {errors.restaurantLogo}
                  </span>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="name"
                    className="block font-medium text-[calc(16vw/19.2)] text-[#22134b]"
                  >
                    8. Enter your full name<span className="text-[#061737]">*</span>
                  </label>
                  <input
                    type="text"
                    className="w-full border-b border-[#22134b] bg-transparent py-2 text-[calc(16vw/19.2)] outline-none"
                    id="fullName"
                    name="fullName"
                    placeholder="e.g. Robert Cooperman"
                    value={formData.fullName}
                    onChange={handleInputChangeWithStorageUpdate}
                    required
                  />
                  <span className="text-[#ec00b1]">
                    {errors.fullName}
                  </span>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="name"
                    className="block font-medium text-[calc(16vw/19.2)] text-[#22134b]"
                  >
                    9. Enter your email address<span className="text-[#061737]">*</span>
                  </label>
                  <input
                    type="text"
                    className="w-full border-b border-[#22134b] bg-transparent py-2 text-[calc(16vw/19.2)] outline-none"
                    id="email"
                    name="email"
                    placeholder="e.g. robertCooperman@gmail.com"
                    value={formData.email}
                    onChange={handleInputChangeWithStorageUpdate}
                    required
                  />
                  <span className="text-[#ec00b1]">
                    {errors.email}
                  </span>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="name"
                    className="block font-medium text-[calc(16vw/19.2)] text-[#22134b]"
                  >
                    10. Enter your phone number<span className="text-[#061737]">*</span>
                  </label>
                  <input
                    type="text"
                    className="w-full border-b border-[#22134b] bg-transparent py-2 text-[calc(16vw/19.2)] outline-none"
                    id="phone"
                    name="phone"
                    placeholder="e.g. 1234567890"
                    value={formData.phone}
                    onChange={handleInputChangeWithStorageUpdate}
                    required
                    maxLength="10"
                    inputMode="numeric"
                  />
                  <span className="text-[#ec00b1]">
                    {errors.phone}
                  </span>
                </div>

                <div className="form-group mb-5">
                  <label
                    htmlFor="deliveryType"
                    className="block font-medium text-[calc(16vw/19.2)] text-[#22134b]"
                  >
                    11. How did you hear about Pizzabox?<span className="text-[#061737]">*</span>
                  </label>
                  <select
                    className="w-full border-b border-[#22134b] bg-transparent py-2 text-[calc(16vw/19.2)] outline-none"
                    id="hearAbout"
                    name="hearAbout"
                    onChange={handleInputChangeWithStorageUpdate}
                    value={formData.hearAbout}
                  >
                    <option value="" className="text-[#C8BFE5]">
                      Please select
                    </option>
                    {hearAboutOptions.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <span className="text-[#ec00b1]">
                    {errors.hearAbout}
                  </span>
                </div>

                <div className="btn-form-container flex flex-col-reverse items-center gap-4">
                  <button
                    className="bg-[#22134b] text-white text-[calc(24vw/19.2)] font-semibold py-2 px-12 rounded-full hover:bg-[#351e75]"
                    onClick={handleSubmitData}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternalForm;

import React from "react"
import BlogCard from "./BlogCard"
import { useNavigate } from "react-router-dom"

const BlogList = (props) => {
  const { blogitems, title, readMore } = props?.data
  const navigate = useNavigate()
  return (
    <div className="md:container">
      <p className="font-semibold text-xl leading-8 font-degularDisplay text-[#6C6C6C] mt-[42px]">
        {title}
      </p>
      <div className="mt-6 gap-7 flex">
        {blogitems?.map((data) => (
          <BlogCard {...data} />
        ))}
      </div>
      <div className="flex mt-11 justify-center mx-auto">
        <button
          className="bg-[#FFBD00] text-black rounded-[70px] py-[13px] px-[77px] "
          onClick={() => navigate("/blog")}
        >
          {readMore}
        </button>
      </div>
    </div>
  )
}

export default BlogList

import React, { useEffect, useState } from "react"
import FooterComponent from "../components/FooterComponent"
import { useNavigate, useParams } from "react-router-dom"
import Header from "../components/HeaderComponent"
import BlogList from "../components/Blog/BlogList"
import BlogInfo from "../components/Blog/BlogInfo"
import SocialMedia from "../components/Blog/SocialMedia"
import { Helmet } from "react-helmet-async"

const BlogDetails = () => {
  const navigate = useNavigate()
  const [blogData, setBlogData] = useState(null)
  const { slug } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://restogptai.com/api/blogs?filters[slug][$eq]=${slug}`
        )
        const data = await response.json()
        setBlogData(data.data) // Assuming the data is under the "data" key
      } catch (error) {
        console.error("Error fetching blog data:", error)
      }
    }

    fetchData()
  }, [slug])
  const data = blogData?.[0]?.attributes?.components
  const seoData = blogData?.[0]?.attributes

  return (
    <>
      <Helmet>
        <title>{seoData?.title}</title>
        <meta name="description" content={seoData?.Description} />
        <meta
          name="keywords"
          content="Customer Loyalty, Flintridge Pizza Kitchen, Upselling, upselling opportunities, Box, SubscriptionBox, Pizza subscription near me, pizza near me, monthly pizza, weekly pizza, pizza of the month, pizza club, online ordering, pizza delivery, pizza subscription service,pizza monthly subscription, pizza box online, pizza box website, pizza subscription, pizzeria benefits, local pizza business, subscription
revenue, customer loyalty, recurring income, Caesar Salad, Cheesecake, Cheeseburger Sub, Cheesesteak, Cookies, French onion soup, Fried Chicken, Pasta, Pierogies, Shrimp, Stromboli, Wings,Sicilian Pizza, New York Pizza, Veggie Pizza, Deep Dish Pizza, By the Slice, Gluten Free Pizza, Neapolitan Pizza, Tomato Pie, Grandma Pizza, Thin Crust Pizza,Stuffed Pizza, Thick Crust Pizza,
Pizza subscriptions, ai pizza, pizza gpt, Pizza box, Pizza memberships, Pizza loyalty, Pizza loyalty programs, Pizza subscription implementation, Pizza subscription, PizzaBox Benefits, pizza subscription case study, pizza per week, weekly pizza, pizza redemption, yearly pizza subscription, customer retention, Customer Loyalty, Flintridge Pizza Kitchen, Upselling, upselling opportunities"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta property="og:title" content={seoData?.title} />
        <meta property="og:description" content={seoData?.Description} />
        <meta
          property="og:url"
          content={`https://pizzabox.ai/blog-details/${slug}`}
        />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoData?.title} />
        <meta name="twitter:description" content={seoData?.Description} />
        <meta
          name="twitter:url"
          content={`https://pizzabox.ai/blog-details/${slug}`}
        />

        <link
          rel="canonical"
          href={`https://pizzabox.ai/blog-details/${slug}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            headline: seoData?.title,
            url: `https://pizzabox.ai/blog-details/${slug}`,
            description: seoData?.Description,
            datePublished: seoData?.publishedAt,
          })}
        </script>
      </Helmet>
      <div className="bg-[#F8F4E6]">
        <Header />
        <div className="bg-[#EAE4CE] rounded-bl-[30px]  md:rounded-bl-[140px] md:pb-[77px] pb-7 pt-[120px] md:pt-[190px] ">
          <div className=" md:container">
            <div className="px-2.5 md:px-0">
              <span
                className="text-[#667085] text-base leading-6 font-normal font-sfPro cursor-pointer"
                onClick={() => navigate("/")}
              >
                HOME /{" "}
              </span>
              <span
                className="text-[#667085] text-base leading-6 font-normal font-sfPro cursor-pointer"
                onClick={() => navigate("/blog")}
              >
                BLOG /{" "}
              </span>
              <span className="text-black text-base leading-6 font-normal font-sfPro">
                Article
              </span>
            </div>
            {data?.map((component, index) => {
              if (
                component?.__component === "widget-list.blog-details-header"
              ) {
                return (
                  <div key={index} className="px-2.5 md:px-0">
                    <h1 className="font-medium md:text-[46px] text-2xl leading-normal font-sfPro mt-8">
                      {component?.title}
                    </h1>
                    <div className="flex items-center text-[#BE000A] mt-8 gap-5">
                      <p className=" text-base font-semibold leading-5 font-sfPro">
                        {component?.date}
                      </p>
                      <p className="text-base font-normal font-degularDisplay leading-5">
                        {component?.readtime}
                      </p>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>

        <div className="md:grid md:grid-cols-6 grid-cols-none md:gap-[68px] gap-0 mt-[46px] lg:container h-full">
          {data?.map((component, index) => {
            if (component?.__component === "widget.blogdetails") {
              return <BlogInfo data={{ ...component }} key={index} />
            }
            if (component?.__component === "widget-list.social-media") {
              return <SocialMedia data={{ ...component }} key={index} />
            }
          })}
        </div>
        {data?.map((component, index) => {
          if (component?.__component === "widget-list.articles") {
            return <BlogList data={{ ...component }} key={index} />
          }
        })}
        <FooterComponent />
      </div>
    </>
  )
}

export default BlogDetails

import axios from "axios";
// import {https} from "https"
// const API_URL ='https://www.resto.velectico.net.in/v1/' // live
// const API_URL ='http://54.152.25.106/restonode/v1/' // local
// const API_URL ='https://orderbyte.io/restonode/v1/v1/' // live
const API_URL = "https://hub.delivery/restonode/v1/"; // new live
const SAMPLE_API = "https://hub.delivery/restonode/v1/test";
// const API_URL = "https://54.152.25.106/restonode/v1/"; // new live
// const SAMPLE_API = "https://54.152.25.106/restonode/v1/test";
// const SAMPLE_API = "http://54.152.25.106/restonode/v1/test";

const API_CREATE_MENU = `${API_URL}createmenu`; // local
const MEALME_API = `${API_URL}ismealme`; // local
// const API_CREATE_MENU_velectico =
//   "https://www.resto.velectico.net.in/v1/createmenu";
const API_CREATE_MENU_velectico = "https://103.172.92.46/resto/v1/createmenu";
export const generateMenu = async (restObj) => {
  // let siteUrl= 'https://www.doordash.com/en-CA/store/yummy-tummy-restaurant-citrus-heights-2691629';

  // const httpsAgent = new https.Agent({
  //   rejectUnauthorized: false,
  // });
  try {
    const Menu = await axios.post(`${API_CREATE_MENU}`, restObj);
    return Menu.data;
  } catch (error) {
    return { status: -1 };
  }
};
export const generateMenuVelectico = async (restObj) => {
  // let siteUrl= 'https://www.doordash.com/en-CA/store/yummy-tummy-restaurant-citrus-heights-2691629';

  // const httpsAgent = new https.Agent({
  //   rejectUnauthorized: false,
  // });
  try {
    const Menu = await axios.post(`${API_CREATE_MENU_velectico}`, restObj);
    return Menu.data;
  } catch (error) {
    return { status: -1 };
  }
};

export const callAPI = async (jsonData) => {
  // let siteUrl= 'https://www.doordash.com/en-CA/store/yummy-tummy-restaurant-citrus-heights-2691629';
  // const httpsAgent = new https.Agent({
  //   rejectUnauthorized: false,
  // });
  try {
    const response = await axios.post(`${MEALME_API}`, jsonData);
    return response.data;
  } catch (error) {
    return { status: -1 };
  }
};

export const latLong = async (jsonData) => {
  try {
    const response = await axios.post(
      "https://api.mealme.ai/location/geocode/v2",
      jsonData,
      {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          "Id-Token": "orderbyte:0b6562b4-efda-45ca-bf00-34bcf57297b4",
        },
      }
    );

    return response.data;
  } catch (error) {
    return { lat: 0, lng: 0 };
  }
};
export const setDifferent = (url) => {
  if (
    url.includes("doordash.com") ||
    url.includes("slicelife.com") ||
    url.includes("toasttab.com") ||
    url.includes("order.toasttab") ||
    url.includes("order.online") ||
    url.includes("ubereats.com") ||
    url.includes("order.chownow.com") ||
    url.includes("chownow.com") ||
    url.includes("https://chownow.com") ||
    url.includes("www.chownow.com") ||
    url.includes("direct.chownow.com")
  ) {
    return true;
  }
  return false;
};

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const makeURL = async (menuUrl) => {
  menuUrl = menuUrl.trim();
  if (!menuUrl.startsWith("https://")) {
    menuUrl = "https://" + menuUrl;
  }
  let url = new URL(menuUrl);
  const urlParams = new URLSearchParams(url.search);
  const cursor = urlParams.get("cursor");
  if (cursor != null) {
    let s = url.search;
    let c = `cursor=` + cursor + `&`;
    url.search = s.replace(c, "");
  }

  // console.log(url.search)
  console.log(url.origin + url.pathname + url.search);
  return url.origin + url.pathname + url.search;
};

export const getAPI = async () => {
  // const httpsAgent = new https.Agent({
  //   rejectUnauthorized: false,
  // });
  try {
    const response = await axios.get(`${SAMPLE_API}`);
    return response.data;
  } catch (error) {
    return { status: -1 };
  }
};

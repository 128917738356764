import { useState } from "react";
import HttpClient from "../../utils/ga/httpClient";

const useUserForm = () => {
  // let siteName = localStorage.getItem("siteName");
  // console.log("siteName form ",siteName);
  const [formData, setFormData] = useState({
    restaurantMenu: "",
    restaurantName: "",
    restaurantType: "",
    restaurantWebsite: "",
    restaurantAddress: "",
    color: "",
    restaurantLogo: "",
    fullName: "",
    email: "",
    phone: "",
    hearAbout: ""
  });

  const [errors, setErrors] = useState({
    restaurantMenu: "",
    restaurantName: "",
    restaurantType: "",
    restaurantWebsite: "",
    restaurantAddress: "",
    color: "",
    restaurantLogo: "",
    fullName: "",
    email: "",
    phone: "",
    hearAbout: ""
  });

  const checkEmailExistInReal = async (email) => {
    let api_key = process.env.REACT_APP_EMAIL_CHECK;
    let url = `https://emailvalidation.abstractapi.com/v1/?api_key=${api_key}&email=${email}`;
    const response = await fetch(url);
    const data = await response.json();
    if (data) {
    }
    return data.is_smtp_valid.text;
  };

  const checkEmailExist = async (email) => {
    let status = true;
    let data = {
      email: email,
    };
    let result = await HttpClient.requestData(
      "retogpt_verify_email",
      "POST",
      data
    );
    if (result) {
    }
    if (result.status === "001") {
      status = false;
    } else {
      status = true;
    }
    return status;
  };

  const validateForm = async (siteName) => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formData.restaurantName) {
      valid = false;
      newErrors.restaurantName = "Restaurant name is required";
    } else {
      newErrors.restaurantName = "";
    }

    if (!formData.restaurantType) {
        valid = false;
        newErrors.restaurantType = "Restaurant type is required";
      } else {
        newErrors.restaurantType = "";
      }

    if (!formData.restaurantMenu) {
        valid = false;
        newErrors.restaurantMenu = "Restaurant menu link is required";
      } else {
        newErrors.restaurantMenu = "";
      }


    if (!formData.restaurantAddress) {
      valid = false;
      newErrors.restaurantAddress = "Restaurant address is required";
    } else {
      newErrors.restaurantAddress = "";
    }

    if (!formData.color) {
        valid = false;
        newErrors.color = "Color is required";
      } else {
        newErrors.color = "";
      }

      if (!formData.fullName) {
        valid = false;
        newErrors.fullName = "FullName is required";
      } else {
        newErrors.fullName = "";
      }

      if (!formData.email) {
        valid = false;
        newErrors.email = "Email is required";
      } else {
        const emailUsed = await checkEmailExist(formData.email); // Wait for result
        const emailExists = await checkEmailExistInReal(formData.email); // Wait for result
    
        if (!emailUsed) {
          valid = false;
          newErrors.email = "* Sorry, this email address has already been used. Please use a different one.";
        } else if (emailExists === "FALSE") {
          valid = false;
          newErrors.email = "* This email does not exist, please enter a valid email.";
        } else {
          newErrors.email = "";
        }
      }

      if (!formData.phone) {
        valid = false;
        newErrors.phone = "Phone number is required";
      } else if (!/^\d{10}$/.test(formData.phone)) {
        valid = false;
        newErrors.phone = "Phone number must be 10 digits";
      } else {
        newErrors.phone = "";
      }

      if (!formData.hearAbout) {
        valid = false;
        newErrors.hearAbout = "Choose any!";
      } else {
        newErrors.hearAbout = "";
      }

    setErrors(newErrors);
    // console.log("Is form valid?", newErrors);
    return valid;
  };

  return [formData, setFormData, errors, validateForm];
};

export default useUserForm;

import React from "react"
import Footer from "../SubscriptionFooter/index"

import Facebook from "../../images/footer/facebook.png"
import Instgram from "../../images/footer/instgram.png"
import Linkedin from "../../images/footer/linkedin.png"

const FooterComponent = () => {
  return (
    <div>
      <div className="bg-[#000]">
        <div className="bg-[#F8F4E6] h-auto rounded-br-[4rem] md:rounded-br-[8rem] xl:rounded-br-[10rem]">
          <div className="w-full md:container px-5 xl:px-0">
            <div className="pt-16 pb-8 sm:pt-20 sm:pb-24 relative">
              <div className="absolute bottom-[-18px] sm:bottom-[-40px] right-0 left-0 z-10 flex justify-center gap-8 sm:gap-20">
                <p>
                  <a href="https://www.facebook.com/pizzaboxai">
                    <img
                      src={Facebook}
                      alt="Facebook"
                      className="w-10 sm:w-full transition-transform duration-300 ease-in-out hover:bounce-scale"
                    />
                  </a>
                </p>
                <p>
                  <a href="https://www.instagram.com/pizzaboxai/">
                    <img
                      src={Instgram}
                      alt="Instgram"
                      className="w-10 sm:w-full transition-transform duration-300 ease-in-out hover:bounce-scale"
                    />
                  </a>
                </p>
                <p>
                  <a href="https://www.linkedin.com/company/restogpt/">
                    <img
                      src={Linkedin}
                      alt="Linkedin"
                      className="w-10 sm:w-full transition-transform duration-300 ease-in-out hover:bounce-scale"
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#000] h-auto">
        <div className="w-full md:container">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default FooterComponent

import React, { useState } from "react"
import BlogCard from "./BlogCard"

const BlogFilters = ({ data }) => {
  const [loadMore, setLoadMore] = useState(true)
  const [filter, setFilter] = useState("All")
  const { blogitems } = data

  const orderData = blogitems && blogitems?.slice()?.reverse()

  const filterData =
    filter === "All"
      ? orderData
      : orderData?.filter((item) => item?.item_type === filter)

  return (
    <div className="md:px-0 px-2.5 ">
      <div className="  md:container mb-8 ">
        <h2 className="font-semibold text-xl leading-8 text-[#6C6C6C] mb-4">
          FILTERS
        </h2>
        <div className="flex gap-3">
          {["All", "Article", "Case study", "Podcast"].map((item, index) => (
            <div className=" flex" key={index}>
              <button
                className={`font-medium rounded ${
                  filter === item ? "bg-[#FFBD00]" : "bg-white"
                }  text-[#49454F] px-3 py-2 `}
                onClick={() => setFilter(item)}
              >
                {item}
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full md:container grid md:grid-cols-3 grid-cols-1 md:gap-7 gap-4">
        {(loadMore ? filterData?.slice(0, 9) : filterData)?.map(
          (data, index) => (
            <BlogCard {...data} key={index} />
          )
        )}
      </div>
      {filterData?.length > 9 && (
        <div className="flex mt-11 justify-center mx-auto">
          <button
            className="bg-[#FFBD00] text-black rounded-[70px] py-[13px] px-[77px]"
            onClick={() => setLoadMore(!loadMore)}
          >
            {loadMore ? "Load More" : "View Less"}
          </button>
        </div>
      )}
    </div>
  )
}

export default BlogFilters

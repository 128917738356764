import React from "react"

const BlogInfo = (props) => {
  const { imgUrl, richText } = props?.data

  return (
    <div className="md:p-[42px] p-2.5 bg-white col-span-4 ">
      <div className="w-full">
        <img src={imgUrl} alt="blog_image" className="w-full" />
      </div>
      <div
        className="prose-th:!text-ss prose max-w-none prose-headings:text-sm prose-headings:font-medium prose-headings:!leading-[22.4px] prose-headings:text-ssBlack prose-p:text-xs prose-p:font-normal prose-p:leading-xl prose-p:text-neutral-500 prose-headings:dark:text-gold prose-p:dark:text-white md:prose-headings:text-base md:prose-p:text-[14px] md:prose-th:!pr-4 md:prose-th:!text-base"
        dangerouslySetInnerHTML={{
          __html: richText,
        }}
      ></div>
    </div>
  )
}

export default BlogInfo

import React, { useEffect, useState } from "react"
import FooterComponent from "../components/FooterComponent/index"
import { useNavigate } from "react-router-dom"
import Header from "../components/HeaderComponent"
import BlogFilters from "../components/Blog/BlogFilters"
import BlogNavHeader from "../components/Blog/BlogNavHeader"
import { Helmet } from "react-helmet-async"

const Blog = () => {
  const navigate = useNavigate()
  const [blogData, setBlogData] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://restogptai.com/api/blogs?filters[slug][$eq]=blog"
        )
        const data = await response.json()
        setBlogData(data.data) // Assuming the data is under the "data" key
      } catch (error) {
        console.error("Error fetching blog data:", error)
      }
    }

    fetchData()
  }, [])
  const data = blogData?.[0]?.attributes?.components

  return (
    <>
      <Helmet>
        <title>PizzaBox AI Blog - Insights for Pizzerias</title>
        <meta
          name="title"
          content="PizzaBox AI Blog - Insights for Pizzerias"
        />
        <meta
          name="description"
          content="Explore the PizzaBox AI Blog for expert insights, tips, and strategies to boost your pizzeria business. Learn about pizza subscriptions, customer loyalty, and more."
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content="Customer Loyalty, Flintridge Pizza Kitchen, Upselling, upselling opportunities, Box, SubscriptionBox, Pizza subscription near me, pizza near me, monthly pizza, weekly pizza, pizza of the month, pizza club, online ordering, pizza delivery, pizza subscription service,pizza monthly subscription, pizza box online, pizza box website, pizza subscription, pizzeria benefits, local pizza business, subscription
    revenue, customer loyalty, recurring income, Caesar Salad, Cheesecake, Cheeseburger Sub, Cheesesteak, Cookies, French onion soup, Fried Chicken, Pasta, Pierogies, Shrimp, Stromboli, Wings,Sicilian Pizza, New York Pizza, Veggie Pizza, Deep Dish Pizza, By the Slice, Gluten Free Pizza, Neapolitan Pizza, Tomato Pie, Grandma Pizza, Thin Crust Pizza,Stuffed Pizza, Thick Crust Pizza,
    Pizza subscriptions, ai pizza, pizza gpt, Pizza box, Pizza memberships, Pizza loyalty, Pizza loyalty programs, Pizza subscription implementation, Pizza subscription, PizzaBox Benefits, pizza subscription case study, pizza per week, weekly pizza, pizza redemption, yearly pizza subscription, customer retention, Customer Loyalty, Flintridge Pizza Kitchen, Upselling, upselling opportunities"
        />

        <link rel="canonical" href="https://pizzabox.ai/blog" />
        <meta
          property="og:title"
          content="PizzaBox AI Blog - Insights for Pizzerias"
        />
        <meta
          property="og:description"
          content="Discover strategies and tips for running a successful pizzeria. From customer loyalty programs to pizza subscription benefits, we've got you covered."
        />
        <meta property="og:url" content="https://pizzabox.ai/blog" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="PizzaBox AI Blog - Insights for Pizzerias"
        />
        <meta
          name="twitter:url"
          content={`https://pizzabox.ai/blog`}
          data-react-helmet="true"
        />
        <meta
          name="twitter:description"
          content="Explore our blog for expert advice on pizzeria growth, customer loyalty, and subscription models."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Blog",
            name: "PizzaBox Blog",
            url: "https://pizzabox.ai/blog",
            description:
              "Discover insightful articles, tips, and updates about pizza subscriptions and how PizzaBox can help pizzerias grow.",
          })}
        </script>
      </Helmet>
      <div className="bg-[#F8F4E6]">
        <div className="px-0 md:px-8 xl:px-0">
          <div id="TOP"></div>
          <Header />
          <div className="bg-[#EAE4CE] rounded-bl-[30px] md:rounded-bl-[140px] pt-[120px] md:pt-[190px]  ">
            {/* //md:pt-48 xl:pt-40 */}
            <div className="md:container">
              <div className="md:px-0 px-2.5">
                <span
                  className="text-[#667085] text-base leading-6 font-normal font-sfPro cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  HOME /{" "}
                </span>
                <span className="text-black text-base leading-6 font-normal font-sfPro">
                  BLOG
                </span>
              </div>

              {data?.map((component, index) => {
                if (component?.__component === "widget-list.blognav") {
                  return <BlogNavHeader {...component} key={index} />
                }
              })}
            </div>
          </div>
          {data?.map((component, index) => {
            if (component?.__component === "widget-list.bloglist") {
              return <BlogFilters data={{ ...component }} key={index} />
            }
          })}
          <FooterComponent />
        </div>
      </div>
    </>
  )
}

export default Blog

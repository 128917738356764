import React from "react"
import "./NotFound.css"

import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async"

const NotFound = () => {
  return (
    <div className="not-found-container">
      <Helmet>
        <title>404 - Page Not Found | PizzaBox AI</title>
        <meta
          name="description"
          content="Oops! The page you are looking for does not exist. Explore our homepage or check out our latest blogs."
        />
        <meta name="robots" content="noindex, nofollow" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "404 - Page Not Found",
            description:
              "Oops! The page you are looking for does not exist. Explore our homepage or check out our latest blogs.",
            url: "https://pizzabox.ai/404",
          })}
        </script>
      </Helmet>
      <h2>404 - Not Found</h2>
      <p>
        Sorry, we couldn't find the page you were looking for. Don’t worry,
        let’s get you back on track:
        <Link to="/">
          <span className="text-blue-600"> Back to Homepage</span>
        </Link>{" "}
        or
        <Link to="/blog">
          {" "}
          <span className="text-blue-600">Explore Blogs</span>
        </Link>
        .
      </p>
    </div>
  )
}

export default NotFound
